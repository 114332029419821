import React, { useEffect } from 'react';

import parse, { domToReact, attributesToProps } from 'html-react-parser';
import type { DOMNode } from 'html-react-parser';

import { getRewrittenUrl } from '@fie/analytics-library';

import { PromoType, getPromoItems } from '../../../sharedModules/getPromoItems';
import { IAnalyticsMethods } from '../../hooks/useAnalytics';
import getLinkElement from '../../hooks/useAnalytics/getLinkElement';
import type { Site } from '../../modules/sites/types/Site';
import type { GenericSharedComponents } from '../../types/GenericSharedComponents';
import type { Translate } from '../../types/Localiser';
import { isElement } from '../../utils/isElement';
import { Promos } from '../GenericComponents/Promos/loadable';

import styles from './styles/deal-widget.css';

interface DealWidgetLinkProps {
  sendAnalytics: IAnalyticsMethods['sendAnalytics'];
  articleUrl: string;
  getAnalyticsData: IAnalyticsMethods['getAnalyticsData'];
  site: Site;
  area: string;
  articleName: string;
  translate: Translate;
  excludeFrom?: string[];
  platform: string;
  starLabel?: string;
  star?: string;
  genericSharedComponents: GenericSharedComponents;
  dealWidgetCollapsibleText?: boolean;
  showReducedText: boolean;
  setLength: (length: number) => void;
  customTrackedWidgetIntroduction: string;
}

export const DealWidgetLinks: React.FC<DealWidgetLinkProps> = ({
  sendAnalytics,
  articleUrl,
  getAnalyticsData,
  site,
  area,
  articleName,
  translate,
  excludeFrom,
  platform,
  star,
  starLabel,
  genericSharedComponents: { TrackedLink },
  dealWidgetCollapsibleText,
  showReducedText,
  setLength,
  customTrackedWidgetIntroduction,
}) => {
  const trackLinkClick = (event): void => {
    const link = getLinkElement(event.target);
    const url = link?.href || '';

    if (link) {
      const domain = new URL(url);

      if (sendAnalytics) {
        const analyticsData = getAnalyticsData();
        sendAnalytics(
          {
            ...analyticsData.event,
            clickType:
              domain?.hostname && articleUrl.includes(domain?.hostname) ? 'internal' : 'retailer',
            prefix: 'Click from',
          },
          null,
          event,
          {
            ...analyticsData.products[0],
            url,
            customTrackingId: link.dataset.customTrackingId || 'hawk-custom-tracking',
            merchant: {
              id: null,
              name: link?.getAttribute('data-link-merchant') || domain.hostname,
              url: '',
              network: '',
            },
          },
        );
      }
    }
  };
  let textlenght = 0;
  const result = parse(customTrackedWidgetIntroduction, {
    replace: (domNode: DOMNode) => {
      if (domNode.type === 'text' && domNode['data']) {
        textlenght += domNode['data']?.length;
      }
      if (isElement(domNode) && domNode.name === 'a') {
        // We store attributes into ref to reuse it later in onClick handler
        const attributes = domNode.attribs ? attributesToProps(domNode.attribs) : { href: '' };
        const customTrackingId = attributes['data-custom-tracking-id'];

        return (
          <TrackedLink
            {...{
              trackLinkClick,
              attributes: {
                ...attributes,
                'data-rawLink': attributes.href,
                href: getRewrittenUrl(
                  attributes.href,
                  site && site.analyticsName,
                  area,
                  customTrackingId,
                  articleUrl,
                  articleName,
                ),
                referrerPolicy: 'no-referrer-when-downgrade',
              },
            }}
          >
            {domNode.children && domToReact(domNode.children)}
          </TrackedLink>
        );
      }

      return domNode;
    },
  });

  useEffect(() => {
    setLength(textlenght);
  }, []);

  const mainContainer = excludeFrom ? 'inactive' : 'main';
  const readmoreClassName = showReducedText ? 'readmore-closed' : 'readmore-open';
  const promoItems =
    star === 'hero' && starLabel
      ? getPromoItems(
          {
            promos: [
              {
                type: platform === 'responsive' ? 'hero' : 'hero-progressive',
                value: translate(starLabel),
                display_value: translate(starLabel),
              },
            ],
          },
          PromoType.ALLOWED,
          false,
        )
      : [];

  return (
    <div className={styles[mainContainer]}>
      {promoItems.length > 0 && <Promos className="dealwidget" items={promoItems} />}
      {result}
      {dealWidgetCollapsibleText && textlenght > 300 && (
        <div className={styles[readmoreClassName]}>
          <span className={styles['text']}>{showReducedText ? 'Read more' : 'Read less'}</span>
          {showReducedText ? (
            <span className={styles['arrow-down']}>&#9660;</span>
          ) : (
            <span className={styles['arrow-up']}>&#9650;</span>
          )}
        </div>
      )}
    </div>
  );
};
